.rainbow-gradient {
  background: linear-gradient(
    to right,
    #ff0000,
    #ff8000,
    #ffff00,
    #00ff00,
    #00ffff,
    #0000ff,
    #8000ff,
    #ff0080
  );
  animation: rainbow 20s linear infinite;
}

@keyframes rainbow {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
} 

.matrix-effect {
  position: relative;
  color: #0f0 !important;
  text-shadow: 0 0 5px #0f0;
}

.matrix-effect::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    rgba(0, 0, 0, 0.9),
    rgba(0, 15, 0, 0.9)
  );
  pointer-events: none;
  z-index: 1;
}

.matrix-effect > * {
  position: relative;
  z-index: 2;
} 